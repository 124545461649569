@import "../../../styles/variables";
@import "../../../styles/breakpoints";

.actionBtn {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 89px;
  height: 32px;
  border: 1px solid $senary-color;
  background: transparent;
  border-radius: $medium-border-radius;
  align-items: center;
  justify-content: center;
  color: $secondary-color;

  &:hover {
    cursor: pointer;
    background-color: $senary-color;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: $primary-color;
  width: 476px;
  height: 296px - 20px;
  border-radius: $default-border-radius;
  padding: 24px;
  width: 480px;

  @include md {
    width: 340px;
    height: 200px;
  }
  @include lg {
    width: 394px;
    height: 200px;
  }
  @include xl {
    width: 480px;
    height: 266px;
  }

  .contentSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .titleSection {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .title {
        font-family: $heading-font-family;
        font-size: 22px;
        font-weight: 700;
        color: $secondary-color;
        text-transform: capitalize;
      }

      .subtitle {
        font-family: $body-font-family;
        font-size: 12px;
        font-weight: 500;
        color: $quinary-color;
        text-transform: capitalize;
      }
    }
  }

  .graphSection {
    width: 420px;
    height: 200px;

    @include md {
      width: 324px;
    }

    @include lg {
      width: 384px;
    }
    @include xl {
      width: 480px;
    }
  }
}
