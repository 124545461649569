.container {
  width: 320px;
  z-index: 99;
  background-color: white;
  border-radius: 8px;
  border: 0.2px solid;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  .closeSection {
    display: flex;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    gap: 12px;

    button {
      width: 100%;
      border-radius: 4px;
      height: 28px;
      border: none;
      font-weight: 600;
      color: #311057;
      background-color: #f1f0f8;

      &:hover {
        cursor: pointer;
        background-color: #d8d7e4;
      }
    }
  }

  .line {
    height: 0.4px;
    background-color: grey;
    width: 100%;
  }

  .footer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;

    p {
      font-size: 12px;
    }
  }
}
