.container {
  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: row;
    margin: 4rem;
    gap: 1rem;

    .thumbnailSection {
      display: flex;
      flex-direction: column;
      gap: 12px;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border: 2.4px solid transparent;

        &:hover {
          cursor: pointer;
          border: 2.4px solid black;
        }
      }
    }

    .renderSection {
      p {
        margin: 0 auto;
        background-image: linear-gradient(25deg, orange, red);
        width: 160px;
        height: 32px;
        border-radius: 4px;
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          background-image: linear-gradient(25deg, red, orange);
        }
      }
      img {
        width: 495px;
        height: 692px;
        object-fit: cover;
      }

      iframe {
        width: 495px;
        height: 692px;
        border: none;
        overflow: hidden;
      }
    }
  }
}

.mobileContainer {
  display: none;

  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin: 1rem;

    .thumbnailSection {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-left: 12px;

      img {
        width: 72px;
        height: 72px;
        object-fit: cover;
        border: 2.4px solid transparent;

        &:hover {
          cursor: pointer;
          border: 2.4px solid black;
        }
      }
    }

    .renderSection {
      p {
        margin: 0 auto;
        background-image: linear-gradient(25deg, orange, red);
        width: 140px;
        height: 28px;
        border-radius: 4px;
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          background-image: linear-gradient(25deg, red, orange);
        }
      }
      img {
        width: 240px;
        height: 322px;
        object-fit: cover;
      }
      iframe {
        width: 240px;
        height: 322px;
        border: none;
        overflow: hidden;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .mobileContainer {
    display: block;
  }

  .container {
    display: none;
  }
}
