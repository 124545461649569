body {
  background-color: white;
  width: 100%;
}
.mobileContainer {
  display: block;
  img {
    width: 100%;
  }

  p {
    font-weight: 500;
    color: black;
    font-size: 20px;
    margin-left: 1rem;
  }

  .colorThumbnail {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-left: 1rem;
    margin-top: 0.4rem;

    .circle {
      width: 32px;
      height: 32px;
      border-radius: 60%;
      outline: none;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    .purple {
      background-color: #c6bcd0;
    }

    .steel {
      background-color: #7d93ab;
    }
    .denim {
      background-color: #28334a;
    }
    .carbon {
      background-color: #545859;
    }
    .carnation {
      background-color: #e3bec3;
    }
  }

  .renderSection {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 12px;

    .render {
      display: flex;
      align-items: center;
      justify-content: center;

      img,
      iframe,
      video {
        width: 90%;
        height: 250px;
        object-fit: contain;
        overflow: hidden;
        border: none;
        border-radius: 8px;
      }

      video {
        margin-left: 1.4rem;
      }
    }

    .thumbnailSection {
      display: flex;
      flex-direction: row;
      gap: 12px;
      overflow-x: scroll;

      img {
        width: 62px;
        height: 62px;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
}

.container {
  display: none;

  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    justify-content: center;

    .renderSection {
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 12px;

      .render {
        img,
        iframe,
        video {
          width: 561px;
          height: 561px;
          border-radius: 8px;
          object-fit: contain;
          overflow: hidden;
          border: none;
        }
      }

      .thumbnailSection {
        display: flex;
        flex-direction: row;
        gap: 12px;

        img {
          width: 88px;
          height: 88px;
          border-radius: 8px;
          object-fit: cover;
          border: 1px solid transparent;

          &:hover {
            cursor: pointer;
            border: 1px solid rgba(128, 128, 128, 0.233);
          }
        }
      }
    }

    .cartSection {
      display: flex;
      flex-direction: column;
      height: 800px;
      overflow: scroll;
      width: 60%;
      margin-left: 2rem;
      gap: 0;
      background-color: white;

      p {
        font-weight: 500;
        color: black;
        font-size: 20px;
        margin-left: 2rem;
      }

      .colorThumbnail {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-left: 2rem;
        margin-top: 0.4rem;

        .circle {
          width: 32px;
          height: 32px;
          border-radius: 60%;
          outline: none;

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }

        .purple {
          background-color: #c6bcd0;
          border: 2px solid transparent;
        }

        .steel {
          background-color: #7d93ab;
          border: 2px solid transparent;
        }
        .denim {
          background-color: #28334a;
          border: 2px solid transparent;
        }
        .carbon {
          background-color: #545859;
          border: 2px solid transparent;
        }
        .carnation {
          background-color: #e3bec3;
          border: 2px solid transparent;
        }
      }
      img {
        width: 100%;
      }

      .color {
        margin-left: 2rem;
        font-size: 24px;
        color: black;
      }

      .thumbnailSection {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-left: 2rem;
        margin-top: 1rem;
        img {
          height: 80px;
          width: 62px;
          border-radius: 4px;
          object-fit: cover;
          border: 1px solid transparent;

          &:hover {
            border: 1px solid rgba(128, 128, 128, 0.485);
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (min-width: 468px) {
  .mobileContainer {
    display: none;
  }

  .container {
    display: block;
  }
}
