@import "./variables";

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: $body-font-family;
  font-size: $font-size-medium;
  background-color: $quaternary-color;
  width: min(100vw, $large-screen);
  margin: 0 auto;
  height: 100vh;
}

h1 {
  font-family: $heading-font-family;
  font-size: 29px;
  font-weight: 700;
  color: $secondary-color;
  text-transform: capitalize;
}

h2 {
  font-size: 25px;
  color: $primary-color;
  font-family: $heading-font-family;
  font-weight: 700;
  text-transform: capitalize;
}

h3 {
  color: $secondary-color;
  font-weight: 700;
}

.primaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gradient;
  width: 162px;
  height: $input-height;
  border-radius: $default-border-radius;
  font-weight: 700;
  color: $primary-color;
  letter-spacing: 0.2px;
  border: none;

  &:hover {
    cursor: pointer;
    background-image: $gradient-hover;
  }
}

.column_gap_10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.column_gap_28px {
  display: flex;
  flex-direction: column;
  padding: 28px;
  gap: 28px;
  width: 100%;
}
