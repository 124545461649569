

.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;


    .leftSection{
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;

        img{

            &:hover{
                cursor: pointer;
            }
        }
    }

    a{
        text-decoration: none;
        color: inherit;
    }
   
}