.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  img {
    width: 100%;
  }
  p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #707070;
  }

  .horizontal_line {
    height: 0.4px;
    width: 100%;
    background-color: #707070;
  }

  .modalPopup {
    width: 80%;
    height: 80vh;
    background-color: rgb(251, 251, 251);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid black;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      display: flex;
      flex-direction: row-reverse;
      align-items: self-end;
      width: 97%;
      gap: 12px;

      button {
        padding: 12px 32px;
        border-radius: 2px;
        background-color: none;
        font-family: Arial, Helvetica, sans-serif;
        border: none;
        outline: none;
      }

      .secondary {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        border: 1px solid #575757;
        color: #222222;
        background-color: white;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
          background-color: white;
        }
      }

      .primary {
        font-size: 18px;
        line-height: 17px;
        font-weight: 700;
        background-color: #222222;
        color: white;

        &:hover {
          cursor: pointer;
          background-color: #575757;
        }
      }
    }

    .content {
      height: 100%;
      width: 100%;
      position: relative;

      img {
        position: absolute;
        bottom: 40%;
        right: 2rem;
        width: 256px;
      }

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }

  .header {
    display: flex;
    margin: 0 auto;
    padding: 16px;
  }

  .mainSection {
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 0 8rem 8rem 1rem;
  }

  .leftSection {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 12px;

    .title {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      color: #222222;
    }

    p {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #707070;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .rightSection {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .gridSection {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 16px;
      column-gap: 16px;

      .imageSection {
        position: relative;
        width: 362px;
        height: 542px;

        .label {
          position: absolute;
          bottom: 12px;
          right: 12px;

          p {
            background-color: rgb(47, 47, 47);
            padding: 8px 12px;
            color: white;
            border-radius: 2px;

            &:hover {
              cursor: none;
            }
          }
        }
      }

      img {
        width: 362px;
        height: 542px;
        border-radius: 2px;
        object-fit: cover;
        border: 1px solid transparent;
        transition: all 0.3s ease-in-out;

        &:hover {
          opacity: 1;
          border: 1px solid grey;
          border-radius: 1px solid #5a5a5a;
          cursor: pointer;
        }
      }
    }

    .titleSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: #222222;

        span {
          font-size: 14px;
          color: #707070;
        }
      }
    }

    .searchSection {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      select {
        padding: 12px 16px;
        border: 1px solid #707070;
        border-radius: 4px;
      }
    }
  }
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  display: none;

  img {
    width: 100%;
  }

  .modalPopup {
    width: 80%;
    height: 80vh;
    background-color: rgb(251, 251, 251);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid black;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      display: flex;
      flex-direction: row-reverse;
      align-items: self-end;
      width: 97%;
      gap: 12px;

      button {
        padding: 8px 16px;
        border-radius: 2px;
        background-color: none;
        font-family: Arial, Helvetica, sans-serif;
        border: none;
        outline: none;
      }

      .secondary {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        border: 1px solid #575757;
        color: #222222;
        background-color: white;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
          background-color: white;
        }
      }

      .primary {
        font-size: 18px;
        line-height: 17px;
        font-weight: 700;
        background-color: #222222;
        color: white;

        &:hover {
          cursor: pointer;
          background-color: #575757;
        }
      }
    }

    .content {
      height: 100%;
      width: 100%;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }

  .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    color: #222222;
    margin-left: 1rem;
  }

  .subtitle {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #575757;
    margin-left: 1rem;
  }

  select {
    padding: 12px 16px;
    border: 1px solid #707070;
    border-radius: 2px;
    color: #222222;
    padding: 12px 24px;
    margin: 1rem;
  }

  .gridSection {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4px;
    column-gap: 4px;
    margin: 0 auto;
    margin-left: 4px;

    img {
      width: 96%;
      height: 224px;
      border-radius: 2px;
      object-fit: cover;
      position: relative;

      &:hover {
        opacity: 0.7;
        border-radius: 1px solid #5a5a5a;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 511px) {
  //Mobile Screen
  .container {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}

@media screen and (min-width: 512px) {
  //Desktop Screen
  .container {
    display: flex;
  }

  .mobileContainer {
    display: none;
  }
}
