@import '../../../../styles/variables';

.container{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    width: 256px - 48px;
    height: 344px - 48px;
    background-color: $primary-color;
    border-radius: $default-border-radius;


    &:hover{
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }


    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .titleSection{
            display: flex;
            flex-direction: column;
            gap: 8px;

            .title{
                font-size: 14px;
                font-weight: 700;
                color: $secondary-color;
                text-transform: capitalize;
            }

            .subtitle{
                font-size: 12px;
                font-weight: 400;
                color: $tertiary-color;
                text-transform: capitalize;
            }
        }
    }

    .color{
        width: 36px;
        height: 6px;
        border-radius: $default-border-radius;
        background-color: green;
    }
}



.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.column{
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title{
        color: $tertiary-color;
        font-size: 12px;
        text-transform: capitalize;
    }

    .value{
        color: $secondary-color;
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
    }
}