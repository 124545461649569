@import "../../../../styles/variables";
@import "../../../../styles/breakpoints";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $secondary-color;
  height: 216px - 104px;
  background-image: url("../../../../../public/assets/backgrounds/infobackground.png");
  background-size: cover;
  background-position: center;
  border-radius: $default-border-radius;
  gap: 14px;
  padding: 28px;
  width: 1024px;

  @include md {
    width: 754px;
  }

  @include lg {
    width: 860px;
  }
  @include xl {
    min-width: 1024px;
  }

  p {
    color: $senary-color;
  }

  .primaryButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gradient;
    width: 142px;
    height: 40px;
    border-radius: $default-border-radius;
    font-weight: 700;
    color: $primary-color;
    letter-spacing: 0.2px;
    border: none;

    &:hover {
      cursor: pointer;
      background-image: $gradient-hover;
    }
  }
}
