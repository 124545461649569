.container {
  width: 100%;
  height: 95vh;
  margin: 0 auto;
  border: 2px solid transparent;
}

@media only screen and (max-width: 768px) {
  .container {
    height: 90vh; /* Adjust height for mobile */
  }
}
