@import "../../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 304px;
  height: 168px - 48px;
  background-color: $primary-color;
  border-radius: $default-border-radius;
  padding: 24px;
  gap: 32px;

  .contentSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .titleSection {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .title {
        font-family: $heading-font-family;
        font-size: 22px;
        color: $secondary-color;
        font-weight: 700;
        text-transform: capitalize;
      }

      .subtitle {
        font-size: 14px;
        color: $senary-color;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    .iconSection {
      img {
        width: 20px;
        height: 20px;
        border: 1px solid $senary-color;
        padding: 6px;
        border-radius: $default-border-radius;

        &:hover {
          cursor: pointer;
          background-color: $secondary-color;
        }
      }
    }
  }
}

@media (min-width: 959px) and (max-width: 1198px) {
  .container {
    background-color: red;
    min-width: 25%;
    max-width: 30%;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 95%;
  }
}

@media (max-width: 479px) {
  .container {
    width: 95%;
  }
}
