.desktopcontainer {
  display: flex;
  flex-direction: column;
  background-color: white;

  img {
    width: 100%;
  }

  .mainContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-left: 5rem;

    .renderSection {
      .render {
        img {
          width: 427px;
          height: 512px;
          object-fit: cover;
        }
        video {
          width: 472px;
          height: 512px;
        }

        iframe {
          border: none;
        }
      }

      .thumbnailSection {
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin-top: 1rem;

        img {
          width: 72px;
          height: 72px;
          object-fit: cover;
          border: 1px solid transparent;

          &:hover {
            cursor: pointer;
            border: 1px solid black;
          }
        }
      }
    }
    .detailSection {
      img {
        height: 667px;
      }
    }
  }
}

.mobilecontainer {
  display: none;
  flex-direction: column;
  background-color: white;

  img {
    width: 100%;
  }
  .renderSection {
    width: 94%;
    height: 320px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      width: 318px;
      height: 320px;
    }

    iframe {
      border: none;
    }
    img {
      width: 94%;
      height: 320px;
      object-fit: cover;
    }
  }

  .thumbnailSection {
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow-x: scroll;
    width: 80%;
    margin-left: 2rem;

    img {
      width: 44px;
      height: 68px;
      object-fit: cover;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid black;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mobilecontainer {
    display: block;
  }

  .desktopcontainer {
    display: none;
  }
}
