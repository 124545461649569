@import "../../../styles/variables";

.graphSection {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 1080px;
}
