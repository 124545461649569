.container {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  border: 2px solid transparent;

  model-viewer {
    width: 100%;
    height: 100vh;
  }

  button {
    background-color: rgb(239, 239, 239);
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
  }
}
