@import "../../../styles/variables";
@import "../../../styles/breakpoints";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 1080px;
  @include xs {
    display: none;
  }

  @include md {
    display: flex;
    width: 810px;
  }

  @include lg {
    display: flex;
    width: 910px;
  }

  @include xl {
    display: flex;
    width: 1080px;
  }
  .notification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  p {
    font-size: 16px;
  }
}
