@import "../../../../styles/variables";

.section {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.largeSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $primary-color;
  padding: 30px;
  border-radius: 2px 2px 0 0;
  align-items: center;
}
