@import "../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 1440px;
  height: 100vh;
  margin: 0 auto;
  padding: 12px;

  .iconSection {
    display: flex;
    justify-content: flex-end;
  }

  .mainSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 56px 112px 112px 112px;

    .left {
      display: flex;
      flex-direction: column;
      gap: 48px;

      .titleSection {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .title {
          font-size: 28px;
          font-family: $heading-font-family;
          text-transform: capitalize;
          font-weight: 700;
          color: $secondary-color;
        }

        .subtitle {
          color: $tertiary-color;
        }
      }

      .percentageSection {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .bar {
          width: 416px;
          height: 18px;
          border-radius: $default-border-radius;
          background-color: $senary-color;
        }

        .success {
          height: 18px;
          width: 200px;
          border-radius: $default-border-radius;
          background-color: #87deb9;
        }
      }
    }

    .right {
      width: 678px - 48px;
      height: 640px - 48px;
      background-color: $primary-color;
      border-radius: $medium-border-radius;
      padding: 24px;
      overflow: scroll;

      .rowSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        label,
        input {
          color: $secondary-color;
        }

        .upload {
          padding: 12px;
        }
      }

      .product {
        color: $secondary-color;
        padding: 0;
      }

      .options {
        display: flex;
        flex-direction: row;
        gap: 12px;

        p {
          padding: 12px;
          background-color: $quaternary-color;
          margin: 1rem 0;

          &:hover {
            cursor: pointer;
            color: $primary-color;
            background-color: $secondary-color;
          }
        }

        .clicked {
          color: $primary-color;
          background-color: $secondary-color;
        }
      }

      p {
        margin: 2rem 0;
      }

      .otherSection {
        font-size: 24px;
        font-weight: 700;
        color: $secondary-color;
        margin-top: 2rem;
      }
      .inputSection {
        display: flex;
        gap: 12px;
        width: 100%;
        margin-top: 2rem;

        .upload {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $quaternary-color;
          border: 2px dotted $tertiary-color !important;
          height: 72px;
        }

        .input50 {
          width: 50%;
        }

        .input25 {
          flex: 70%;
          display: flex;
          gap: 12px;
          flex-direction: column;

          label {
            color: $tertiary-color;
            display: block;
          }

          input {
            padding: 12px;
            border: 1px solid $senary-color;
            border-radius: $medium-border-radius;
            outline: none;
            font-weight: 600;
            color: $secondary-color;
            font-size: 16px;
          }

          select {
            padding: 12px;
            border: 1px solid $senary-color;
            border-radius: $medium-border-radius;
            background-color: transparent;
          }
        }

        .input75 {
          flex: 20%;
          display: flex;
          gap: 12px;
          flex-direction: column;

          label {
            color: $tertiary-color;
          }

          input {
            padding: 12px;
            border: 1px solid $senary-color;
            border-radius: $medium-border-radius;
            outline: none;
            font-weight: 600;
            color: $secondary-color;
            font-size: 16px;
          }

          select {
            padding: 12px;
            border: 1px solid $senary-color;
            border-radius: $medium-border-radius;
            background-color: transparent;
          }
        }

        .inputequal {
          flex: 20%;
          display: flex;
          gap: 12px;
          flex-direction: column;
          width: 140px;

          label {
            color: $tertiary-color;
          }

          input {
            padding: 12px;
            border: 1px solid $senary-color;
            border-radius: $medium-border-radius;
            outline: none;
            font-weight: 600;
            color: $secondary-color;
            font-size: 16px;
          }

          select {
            padding: 12px;
            border: 1px solid $senary-color;
            border-radius: $medium-border-radius;
            background-color: transparent;
          }
        }
      }

      .line {
        height: 1px;
        background-color: $senary-color;
        margin: 12px 0;
      }

      .heading {
        font-size: 24px;
        font-weight: 700;
        color: $secondary-color;
        font-family: $heading-font-family;
      }

      .title {
        font-size: 1;
      }
    }
  }

  .footerSection {
    display: flex;
    justify-content: flex-end;
    background-color: $primary-color;
    height: 112px;
    width: 100%;
    position: absolute;
    bottom: 0;

    .buttonSection {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-right: 4rem;
    }

    .primary {
      height: 48px;
      width: 144px;
      color: white;
      font-weight: 700;
      border-radius: $default-border-radius;
      background-image: linear-gradient(25deg, orange, red);
      border: none;

      &:hover {
        cursor: pointer;
        background-image: linear-gradient(25deg, red, orange);
      }
    }

    .secondary {
      height: 48px;
      width: 168px;
      color: $secondary-color;
      border: 1px solid $senary-color;
      font-weight: 700;
      border-radius: $default-border-radius;
      background-color: $primary-color;

      &:hover {
        cursor: pointer;
        background-color: $senary-color;
        color: $secondary-color;
      }
    }
  }
}
