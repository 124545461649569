@import "../../../../styles/variables";
@import "../../../../styles/breakpoints";

.container {
  height: 324px - 48px;
  width: 476px;
  background-color: $primary-color;
  border-radius: $default-border-radius;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 480px;

  @include md {
    width: 324px;
  }

  @include lg {
    width: 394px;
    height: 200px;
  }
  @include xl {
    width: 480px;
    height: 266px;
  }

  .titleSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .heading {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .key {
        font-size: 22px;
        font-weight: 700;
        color: $secondary-color;
        text-transform: capitalize;
        font-family: $heading-font-family;
      }

      .value {
        color: $tertiary-color;
        font-size: 14px;
        text-transform: capitalize;
      }
    }

    .actionSection {
      display: flex;
      flex-direction: row;

      .actionBtn {
        display: flex;
        flex-direction: row;
        gap: 12px;
        width: 89px;
        height: 32px;
        border: 1px solid $senary-color;
        background: transparent;
        border-radius: $medium-border-radius;
        align-items: center;
        justify-content: center;
        color: $secondary-color;

        &:hover {
          cursor: pointer;
          background-color: $senary-color;
        }
      }
    }
  }

  .contentSection {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 12px;

    .card {
      height: 152px - 24px;
      width: 160px - 24px;
      background-color: $quaternary-color;
      border-radius: $medium-border-radius;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 24px;
      gap: 24px;

      .indicator {
        width: 20px;
        height: 20px;
        border-radius: 60%;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 6px;

        .key {
          color: $tertiary-color;
        }
      }
    }
  }
}

.purple {
  background-color: #a78dc5;
}

.yellow {
  background-color: #fad190;
}

.orange {
  background-color: #fda5a5;
}
