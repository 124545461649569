.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 12px;

  img {
    width: 100%;
  }
  .mainSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .renderSection {
        img {
          width: 666px;
          height: 666px;
        }
      }

      .thumbnailSection {
        display: flex;
        flex-direction: row;
        gap: 8px;
        width: 666px;
        overflow-x: scroll;
        img {
          width: 128px;
          height: 128px;
          object-fit: contain;
        }
      }
    }

    .rightSection {
      height: 800px;
      overflow-y: scroll;
    }
  }
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  display: none;

  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .renderSection {
      display: flex;
      margin: 0 auto;
      img {
        width: 420px;
        height: 420px;
        object-fit: contain;
      }
    }

    .thumbnailSection {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      overflow-x: scroll;
      gap: 4px;
      img {
        width: 72px;
        height: 72px;
        object-fit: contain;
      }
    }
  }

  .footerSection {
    margin-top: 12px;
  }
}

@media (min-width: 468px) {
  //Desktop Container
  .container {
    display: block;
  }

  .mobileContainer {
    display: none;
  }
}

@media (max-width: 467px) {
  //Mobile Container
  .container {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}
