@import "../../../../styles/variables";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid $senary-color;
  background: transparent;
  width: 192px;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: $default-border-radius;
  &:hover {
    background: $senary-color;
  }
}
