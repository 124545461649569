body {
  background-color: white;
}

.desktopContainer {
  display: none;
  background-color: white;

  img {
    width: 100%;
  }
}
.container {
  display: flex;
  flex-direction: column;

  .tryon {
    margin: 0 auto;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 700;
    background-image: linear-gradient(orange, red);
    color: white;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  .renderSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 0 auto;
      margin: 1rem 0;
      background-image: linear-gradient(25deg, orange, red);
      width: 160px;
      height: 32px;
      border-radius: 4px;
      color: white;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
        background-image: linear-gradient(25deg, red, orange);
      }
    }

    iframe {
      border: none;
      width: 335px;
      height: 502px;
    }

    video {
      width: 335px;
      height: 302px;
      object-fit: contain;
    }
    img {
      width: 335px;
      height: 502px;
      object-fit: cover;
      margin: 0 auto;
    }
  }

  .thumbnailSection {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 12px;
    overflow-x: scroll;
    width: 100%;
    margin-top: 2rem;

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border: 1px solid transparent;

      &:hover {
        cursor: pointer;
        border: 1px solid;
      }
    }
  }
}

@media only screen and (min-width: 767px) {
  .container {
    display: none;
  }

  .desktopContainer {
    display: block;
    margin: 0 auto;

    .mainContainer {
      display: flex;
      flex-direction: row;

      .renderSection {
        height: 512px;
        margin: 0 auto;

        p {
          margin: 0 auto;
          margin-bottom: 1rem;
          background-image: linear-gradient(25deg, orange, red);
          width: 200px;
          height: 40px;
          border-radius: 4px;
          color: white;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            cursor: pointer;
            background-image: linear-gradient(25deg, red, orange);
          }
        }

        

        video {
          width: 737px;
          height: 504px;
        }

        iframe {
          border: none;
          width: 512px;
          height: 512px;
        }
        img {
          width: 512px;
          height: 512px;
          object-fit: contain;
        }

        .videoSection {
          width: 578px;
          height: 502px;
          object-fit: contain;
          padding: 24px;
        }
      }

      .cartSection {
        display: flex;
      }
    }

    .thumbnailSection {
      display: flex;
      flex-direction: row;
      gap: 24px;
      margin-top: 2rem;
      margin-left: 4rem;
      width: 364px;
      overflow-x: scroll;
      padding: 0 24px;

      img {
        height: 120px;
        width: 82px;
        object-fit: cover;
        border: 1px solid transparent;
        transition: all 0.2s;

        &:hover {
          cursor: pointer;
          border: 1px solid;
        }
      }
    }
  }
}
