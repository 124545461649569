.container {
  * {
    margin: 0;
    padding: 0;
  }
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .imageCarousel {
    display: flex;
    flex-direction: row;
    padding: 12px;

    img {
      width: 512px;
      height: 512px;
      object-fit: cover;
    }
  }

  .thumbnail {
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow-x: scroll;
    width: 512px;
    img {
      width: 64px;
      height: 64px;
      object-fit: cover;
      border: 1px solid transparent;
      transition: all 0.3s;

      &:hover {
        border: 1px solid grey;
        cursor: pointer;
      }
    }
  }
}
