.container {
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: grey;

  iframe {
    width: 100%;
    height: 100vh;
  }
}
