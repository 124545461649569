.container {
  width: 100vw;
  height: 100%;
  background-color: white;

  .glbSection {
    display: flex;
    margin: 0 auto;
    position: relative;
    border: 2px solid orange;

    p {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 20px;
      padding: 12px;
      background-image: linear-gradient(25deg, orange, red);
      color: white;
      border-radius: 4px;
    }
  }
}
