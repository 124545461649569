.desktopcontainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  img {
    width: 100%;
  }

  .secondContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 48px;
    padding: 0 24px;

    img {
      width: 100%;
    }
  }

  .maincontainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 0 48px;
    .renderSection {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .render {
        width: 658px;
        height: 984px;

        video {
          width: 100%;
          height: 600px;
          object-fit: cover;
        }

        iframe {
          border: none;
        }

        img {
          width: 658px;
          height: 840px;
          object-fit: cover;
        }
      }

      .thumbnailSection {
        display: flex;
        flex-direction: column;
        gap: 8px;

        img {
          height: 166px;
          width: 110px;
          object-fit: cover;
          border: 1px solid transparent;

          &:hover {
            cursor: pointer;
            border: 1px solid grey;
          }
        }
      }
    }

    .detailSection {
    }
  }
}

.mobilecontainer {
  display: none;
  flex-direction: column;
  background-color: white;

  img {
    width: 100%;
    height: 480px;
    object-fit: contain;
  }

  .renderSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 480px;
    margin: 0 auto;

    .render {
      width: 320px;
      height: 480px;

      video {
        width: 100%;
        height: 400px;
      }

      iframe {
        border: none;
      }

      img {
        object-fit: cover;
      }
    }
  }

  .thumbnailSection {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 12px;
    overflow-x: scroll;
    width: 80%;

    img {
      width: 64px;
      height: 64px;
      object-fit: cover;
      border: 1px solid transparent;

      &:hover {
        cursor: pointer;
        border: 1px solid grey;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mobilecontainer {
    display: block;
  }

  .desktopcontainer {
    display: none;
  }
}
