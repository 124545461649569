.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 12px;

  img {
    width: 100%;
  }
  .mainSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;

    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .renderSection {
        margin: 0 auto;
        .textSection {
          display: flex;
          margin: 0 auto;
          align-items: center;
          justify-content: center;
          padding-bottom: 2rem;
          p {
            background-color: black;
            color: white;
            width: 70%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;

            &:hover {
              cursor: pointer;
              opacity: 0.9;
            }
          }
        }

        img,
        iframe,
        video {
          width: 512px;
          height: 512px;
          object-fit: contain;
          border: none;
          overflow: hidden;
          padding: 24px 0;
          border-radius: 4px;
        }
      }

      .thumbnailSection {
        display: flex;
        flex-direction: row;
        gap: 8px;
        width: 666px;
        overflow-x: scroll;
        img {
          width: 128px;
          height: 128px;
          object-fit: contain;
          border: 1px solid transparent;

          &:hover {
            cursor: pointer;
            border: 1px solid grey;
          }
        }
      }
    }

    .rightSection {
      margin-top: 2rem;
      height: 800px;
      width: 666px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 12px;

      button {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        color: white;
        font-weight: 700;
        border: none;
        outline: none;

        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }
      }

      h1 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 22px;
        font-weight: 100;
        line-height: 31px;
        color: #222222;
      }

      h2 {
      }

      p {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        line-height: 17px;
        font-weight: 400;
        text-decoration: underline;
      }

      .color {
        font-family: serif;
        font-size: 14px;
        line-height: 10px;
        font-weight: 400;
        color: #000000;
      }
    }
  }
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  display: none;

  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .renderSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70%;
      margin: 0 auto;
      .textSection {
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        width: 100%;
        p {
          background-color: black;
          color: white;
          width: 100%;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;

          &:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
      }

      p {
        padding: 12px 18px;
        background-color: black;
        color: white;
        align-items: center;
      }

      img,
      iframe,
      video {
        width: 420px;
        height: 420px;
        object-fit: contain;
        border: none;
        overflow: hidden;
        padding-top: 12px;
        margin-top: 1rem;
      }
    }

    .thumbnailSection {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      overflow-x: scroll;
      gap: 4px;
      img {
        width: 72px;
        height: 72px;
        object-fit: contain;
      }
    }
  }

  .contentSection {
    margin-top: 2rem;
    overflow-y: scroll;
    width: 90%;
    margin: 0 auto;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      color: white;
      font-weight: 700;
      border: none;
      outline: none;

      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }

    h1 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 22px;
      font-weight: 100;
      line-height: 31px;
      color: #222222;
    }

    h2 {
    }

    p {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 12px;
      line-height: 17px;
      font-weight: 400;
      text-decoration: underline;
    }

    .color {
      font-family: serif;
      font-size: 14px;
      line-height: 10px;
      font-weight: 400;
      color: #000000;
    }
  }

  .footerSection {
    margin-top: 12px;
  }
}

@media (min-width: 468px) {
  //Desktop Container
  .container {
    display: block;
  }

  .mobileContainer {
    display: none;
  }
}

@media (max-width: 467px) {
  //Mobile Container
  .container {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}
