@import "../../../styles/variables";

.container {
  display: flex;
  flex-direction: row;
  width: min(100vw, 1440px);
  background-color: #f1f0f8;

  .navbar {
    position: sticky;
    top: 0;
  }

  .column {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    padding: 28px;
    gap: 28px;
    overflow-y: auto;
  }
}
