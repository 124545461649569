.container {
  width: 100%;

  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: row;

    .renderSection {
      iframe,
      video {
        border: none;
        overflow: hidden;
        width: 672px;
        height: 672px;
        border-radius: 8px;
      }
      img {
        width: 672px;
        height: 672px;
      }
    }

    .cartSection {
      margin-left: 2rem;
      img {
        width: 512px;
      }
    }

    .colorSection {
      margin-left: 4rem;
      p {
        color: black;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .colors {
        display: flex;
        gap: 12px;

        .black {
          width: 48px;
          height: 48px;
          border-radius: 60%;
          background-color: black;
          border: 2px solid transparent;
          &:hover {
            cursor: pointer;
            border: 2px solid #64ed73;
          }
        }

        .green {
          width: 48px;
          height: 48px;
          border-radius: 60%;
          background-color: #2e6464;
          border: 2px solid transparent;
          &:hover {
            cursor: pointer;
            border: 2px solid #64ed73;
          }
        }

        .purple {
          width: 48px;
          height: 48px;
          border-radius: 60%;
          background-color: #4814a0;
          border: 2px solid transparent;
          &:hover {
            cursor: pointer;
            border: 2px solid #64ed73;
          }
        }

        .white {
          width: 48px;
          height: 48px;
          border-radius: 60%;
          background-color: rgb(239, 239, 239);
          border: 2px solid transparent;
          &:hover {
            cursor: pointer;
            border: 2px solid #64ed73;
          }
        }
      }
    }
  }

  .thumbnailSection {
    width: 740px;
    display: flex;
    flex-direction: row;
    margin-left: 1rem;

    img {
      width: 92px;
      height: 82px;
      object-fit: contain;
      border: 1px solid transparent;
      &:hover {
        cursor: pointer;
        border: 1px solid black;
      }
    }
  }
}

.mobileContainer {
  display: none;
  img {
    width: 100%;
  }

  .renderSection {
    img {
      width: 100%;
    }

    iframe {
      display: flex;
      margin: 0 auto;
      width: 90%;
      height: 400px;
      border: none;
      border-radius: 8px;
    }

    video {
      display: flex;
      margin: 0 auto;
      width: 90%;
      height: 400px;
      border-radius: 8px;
    }
  }

  .colorSection {
    margin-left: 2rem;
    p {
      color: black;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .colors {
      display: flex;
      gap: 12px;

      .black {
        width: 32px;
        height: 32px;
        border-radius: 60%;
        background-color: black;
        border: 2px solid transparent;
        &:hover {
          cursor: pointer;
          border: 2px solid #64ed73;
        }
      }

      .green {
        width: 32px;
        height: 32px;
        border-radius: 60%;
        background-color: #2e6464;
        border: 2px solid transparent;
        &:hover {
          cursor: pointer;
          border: 2px solid #64ed73;
        }
      }

      .purple {
        width: 32px;
        height: 32px;
        border-radius: 60%;
        background-color: #4814a0;
        border: 2px solid transparent;
        &:hover {
          cursor: pointer;
          border: 2px solid #64ed73;
        }
      }

      .white {
        width: 32px;
        height: 32px;
        border-radius: 60%;
        background-color: rgb(239, 239, 239);
        border: 2px solid transparent;
        &:hover {
          cursor: pointer;
          border: 2px solid #64ed73;
        }
      }
    }
  }

  .thumbnailSection {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    overflow-x: scroll;
    gap: 12px;
    img {
      width: 52px;
      height: 52px;
    }
  }
}

@media screen and (max-width: 480px) {
  .mobileContainer {
    display: block;
  }

  .container {
    display: none;
  }
}
