@import "../../../styles/variables";

.container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  height: $input-height;
  width: 142px;
  background-color: $primary-color;
  border-radius: $default-border-radius;
  padding: 0 8px;
}
select {
  color: $tertiary-color;
  font-size: 14px;
  background-color: transparent;
  border: none;
}
