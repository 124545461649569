@import "../../../styles/variables";

.search {
  width: $icon-medium-size;
  height: $icon-medium-size;
}

.bordered {
  border: 1px solid $senary-color;
}

.icon_background {
  width: 16px;
  height: 16px;
  background-color: $primary-color;
  padding: 12px;
  border-radius: 50%;
}

.icon_squared {
  width: 16px;
  height: 16px;
  background-color: $primary-color;
  padding: 12px;
  border-radius: $default-border-radius;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    background-color: $senary-color;
  }
}

.profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.checkIcon {
  width: 22px;
  height: 22px;
}

.megaContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  p {
    color: $quinary-color;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 152px - 20px;
  height: 152px - 20px;
  border-radius: $default-border-radius;
  background-color: $quaternary-color;
  padding: 10px;
  gap: 18px;
  transition: all 0.3s;

  .optionMenu {
    display: flex;
    justify-content: flex-end;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background-color: darken($quaternary-color, 5%);
    cursor: pointer;
  }
}
