.container {
  display: none;
  flex-direction: column;

  background-color: rgb(231, 230, 230);
  img {
    width: 100%;
  }

  .maincontainer {
    width: 100%;
    height: 100vh;
    background-color: white;
    .renderSection {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;

      iframe {
        width: 300px;
        height: 206px;
        border: none;
        overflow: hidden;
      }
      img {
        width: 300px;
        height: 206px;
        object-fit: contain;
      }
    }

    .thumbnailSection {
      display: flex;
      gap: 12px;
      margin-bottom: 1rem;
      width: 80%;
      margin-left: 2rem;
      overflow: scroll;
      img {
        width: 62px;
        height: 62px;
        object-fit: cover;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid maroon;
          cursor: pointer;
        }
      }
    }
  }
}

.desktopContainer {
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
  }

  .maincontainer {
    display: flex;
    background-color: white;
    padding: 12px;
    margin-left: 6rem;

    .renderSection {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 518px;
      img {
        width: 518px;
        height: 354px;
      }

      video {
        width: 518px;
        height: 354px;
      }

      iframe {
        border: none;
        scrollbar-width: none;
      }
      iframe::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
      }

      .thumbnailSection {
        display: flex;
        flex-direction: row;

        gap: 12px;
        width: 500px;
        overflow: scroll;
        img {
          width: 72px;
          height: 72px;
          object-fit: cover;
          border: 1px solid transparent;
          &:hover {
            border: 1px solid maroon;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    display: block;
  }

  .desktopContainer {
    display: none;
  }
}
