@import "../../../styles/variables";

.container {
  width: 288px;
  height: $input-height;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  border-radius: $default-border-radius;
  background-color: $primary-color;
  align-items: center;
  padding-left: 12px;

  input {
    outline: none;
    border: none;
    width: 100%;

    &::placeholder {
      font-family: $body-font-family;
    }
  }
}

.bordered {
  border: 1px solid $senary-color;
}
