.container {
  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 800px;

    p {
      color: black;
      font-weight: 500;
      font-size: 16px;
    }

    .colorthumbnailSection {
      margin-left: 2rem;
      display: flex;
      flex-direction: row;
      gap: 8px;
      img {
        width: 64px !important;
        height: 64px;
        border: 1px solid transparent;
        object-fit: contain;

        &:hover {
          cursor: pointer;
          border: 1px solid;
        }
      }
    }

    .renderSection {
      width: 666px;
      height: 666px;
      margin-left: 4rem;
      p {
        margin-top: 1rem;
        padding: 12px 16px;
        background-image: linear-gradient(25deg, orange, red);
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        &:hover {
          cursor: pointer;
          background-image: linear-gradient(25deg, red, orange);
        }
      }

      iframe,
      video {
        width: 666px;
        height: 666px;
        border: none;
        overflow: hidden;
        object-fit: contain;
      }

      img {
        width: 666px;
        height: 666px;
        object-fit: contain;
      }
    }

    .cartSection {
      padding-top: 20px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 0;

      p {
        margin-left: 2rem;
      }

      // img {
      //   width: 748px;
      // }
    }
  }

  .thumbnailSection {
    width: 740px;
    display: flex;
    flex-direction: row;
    margin-left: 6%;
    margin-top: 1rem;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
      border: 1px solid transparent;
      padding: 10px;
      border-radius: 2px;

      &:hover {
        cursor: pointer;
        border: 1px solid black;
      }
    }
  }
}

.mobilecontainer {
  display: none;
  img {
    width: 100%;
  }

  .popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(236, 236, 236);
    display: flex;
    gap: 12px;
    overflow: hidden;

    iframe {
      width: 100%;
    }
  }

  .closeIcon {
    position: absolute;
    right: 12px;
    top: 12px;
    font-weight: 700;

    &:hover {
      cursor: pointer;
    }
  }

  .mainSection {
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    .renderSection {
      margin: 0 auto;
      width: 90%;

      p {
        margin-top: 1rem;
        padding: 12px 16px;
        background-image: linear-gradient(25deg, orange, red);
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        &:hover {
          cursor: pointer;
          background-image: linear-gradient(25deg, red, orange);
        }
      }
      iframe,
      video {
        width: 100%;
        height: 300px;
        border: none;
        overflow: hidden;
      }

      img {
        height: 300px;
        object-fit: contain;
      }
    }
  }

  p {
    color: black;
    font-weight: 500;
    font-size: 16px;
  }

  .colorlabel {
    margin-left: 2rem;
    font-size: 18px;
    font-weight: 500;
  }

  .colorthumbnailSection {
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
    gap: 8px;
    img {
      width: 64px;
      height: 64px;
    }
  }

  .thumbnailSection {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    overflow-x: auto;

    img {
      width: 65px;
      height: 65px;
      object-fit: contain;
      border: 1px solid transparent;
      padding: 10px;

      &:hover {
        cursor: pointer;
        border: 0.5px solid black;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    display: none;
  }

  .mobilecontainer {
    display: block;
  }
}
