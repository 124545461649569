.container {
  width: 100%;
  height: 100vh;

  img {
    width: 100%;
  }

  .mainsection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .renderSection {
        margin: 0 auto;
        img,
        video,
        iframe {
          width: 512px;
          height: 512px;
          border: none;
        }
      }

      .thumbnailSection {
        display: flex;
        flex-direction: row;
        gap: 8px;
        width: 666px;
        overflow-x: scroll;

        img {
          width: 120px;
          height: 120px;
          object-fit: contain;
        }
      }
    }

    .rightSection {
      height: 800px;
      overflow-y: scroll;

      span {
        font-size: 14px;
        color: rgb(96, 96, 96);
      }

      h2 {
        color: rgb(65, 65, 65);
        margin-left: 3rem;
        font-weight: 400;
      }

      .thumbnailSection {
        display: flex;
        flex-direction: row;
        gap: 4px;
        margin-left: 3rem;

        img {
          height: 72px;
          width: 72px;
          border: 1px solid transparent;
          margin-top: 1rem;

          &:hover {
            cursor: pointer;
            border: 1px solid grey;
          }
        }
      }
    }
  }
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  display: none;

  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: column;
    gap: 12px;

    // .leftSection {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 12px;

    .renderSection {
      width: 100%;
      margin: 0 auto;

      img,
      video,
      iframe {
        width: 100%;
        height: 372px;
        object-fit: contain;
        border: none;
      }
    }

    .thumbnailSection {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      overflow-x: scroll;
      gap: 4px;
      img {
        width: 72px;
        height: 72px;
        object-fit: contain;
      }
    }
  }

  .footerSection {
    margin-top: 12px;
  }
}

@media (min-width: 468px) {
  //Desktop Container
  .container {
    display: block;
  }

  .mobileContainer {
    display: none;
  }
}

@media (max-width: 467px) {
  //Mobile Container
  .container {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}
