@import '../../../styles/variables';


.container{
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title{
        font-family: $heading-font-family;
        font-size: 29px;
        font-weight: 700;
        color: $secondary-color;
    }

    .subtitle{
        color: $quinary-color;
        font-size: 16px;
    }
}