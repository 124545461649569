.container {
  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .renderSection {
      display: flex;
      margin-left: 10rem;

      img,
      iframe,
      video {
        width: 550px;
        height: 500px;
        object-fit: contain;
        border: none;
        overflow: hidden;
      }
    }

    .cartSection {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 600px;
      scrollbar-width: thin;

      img {
        width: 600px;
        margin-bottom: 0.4px;
        margin-left: 10px;
        border: 1px solid transparent;
      }
    }
  }

  .thumbnailSection {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-left: 120px;
    width: 612px;
    margin-bottom: 24px;

    img {
      width: 84px;
      height: 84px;
      object-fit: cover;
      border-radius: 4px;
      border: 1px solid transparent;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        border: 1px solid grey;
        box-sizing: border-box;
      }
    }
  }
}

.mobilecontainer {
  display: none;

  img {
    width: 100%;
  }

  .mainSection {
    display: flex;
    flex-direction: row;

    .renderSection {
      display: flex;
      margin: 0 auto;
      img,
      iframe,
      video {
        width: 250px;
        height: 250px;
        object-fit: contain;
        border: none;
      }
    }
  }

  .thumbnailSection {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    overflow-x: scroll;
    margin-top: 24px;

    img {
      width: 65px;
      height: 65px;
      object-fit: contain;
      border: 1px solid transparent;
      padding: 7px;

      &:hover {
        cursor: pointer;
        border: 0.5px solid black;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    display: none;
  }

  .mobilecontainer {
    display: block;
  }
}
