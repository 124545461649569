@import '../../../../styles/variables';


.container{
    height: 200px;
    width: 100%;
    background-color: $secondary-color;
    border-radius: $default-border-radius;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;


    img{
        height: 100%;
    }

    .titleSection{
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title{
            font-family: $heading-font-family;
            font-size: 24px;
            font-weight: 700;
            color: $primary-color;
            text-transform: capitalize;
        }

        .subtitle{
            font-family: $body-font-family;
            font-size: 12px;
            font-weight: 300;
            color: $senary-color;
            text-transform: capitalize;
        }
    }
}