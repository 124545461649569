.container {
  width: 100%;
  height: 100vh;

  iframe {
    width: 100%;
    height: 100vh;
    border: none;
    overflow: hidden;
  }
}
