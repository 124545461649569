@import '../../../styles/styles.module.scss';


.container{
    height: 658px - 48px;
    background-color: $primary-color;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px;
    border-radius: $default-border-radius;

    LibrayHeader{
        border: 1px solid $tertiary-color;
    }

    .contentSection{
        display: flex;
        flex-direction: column;
        gap: 12px;

        .files{
            display: grid;
            grid-template-columns: repeat(6, 1fr); /* Creates 6 columns with equal width */
            grid-template-rows: auto auto;
            gap: 12px;
        }
        
        .loadbutton{
            display: flex;
            justify-content: flex-end;

            button{
                width: 104px;
                height: 32px;
                border-radius: 4px;
                background-color: transparent;
                border: 1px solid $tertiary-color;
                color: $tertiary-color;

                &:hover{
                    cursor: pointer;
                    background-color: $senary-color;
                    color: $secondary-color;
                }
            }
        }
    }
}