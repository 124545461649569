@import "../../../styles/variables";
@import "../../../styles/styles.module.scss";

.large {
  width: 192px !important;
}

.secondaryBtn {
  background-color: transparent;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    color: $secondary-color;
    background-color: $senary-color;
  }
}

.primaryBtn {
  background-image: linear-gradient(25deg, orange, red);
  color: white !important;

  &:hover {
    cursor: pointer;
    background-image: linear-gradient(25deg, red, orange);
  }
}

.buttonwithicon {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  border: 1px solid $senary-color;
  border-radius: $default-border-radius;
  width: 104px - 2px;
  height: 40px - 2px;
  color: $senary-color;
}

.primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gradient;
  width: 162px;
  height: $input-height;
  border-radius: $default-border-radius;
  font-weight: 700;
  color: $primary-color;
  letter-spacing: 0.2px;
}

.secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary-color;
  width: 162px;
  height: $input-height;
  border-radius: $default-border-radius;
  border: 1px solid $senary-color;
  color: $secondary-color;
  font-weight: 700;
  letter-spacing: 0.2px;
  gap: 12px;
}

.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
