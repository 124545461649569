* {
  margin: 0;
  padding: 0;
}
body {
  width: 1440px;
  margin: 0 auto;
}

h2 {
  color: #121239;
}

p {
  color: #8c809e;
}

.externalrejections {
  ul {
    display: flex;
    flex-direction: row;
  }
  li {
    list-style-type: none;
    padding: 12px;
    border: 1px solid #121239;
  }
  h2 {
    font-size: 18px;
    color: #121239;
  }
  img {
    width: 320px;
    border: 1px solid #8c809e;
    border-radius: 4px;
    margin: 1rem 0;
  }
}
.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  height: 100vh;
  overflow-y: scroll;
  padding: 24px;
  width: 100%;

  .headerSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    img {
      width: 64px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #f1f0f8;
    font-weight: 700;
    font-family: "Inter";
    position: sticky;
    top: -24px;
  }

  th,
  td {
    padding: 16px;
    text-transform: capitalize;
  }

  button {
    padding: 12px;
    background: transparent;
    border: none;
    border-radius: 4px;
    border: 2px solid #f1f0f8;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      border: 2px solid #f1f0f8;
      background: #f1f0f8;
    }
  }
}

.rejectionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 320px;
  background-color: #f1f0f8;

  .rejections {
    display: flex;
    flex-direction: row;
    padding: 12px;
    gap: 12px;
    overflow-x: scroll;

    .rejection {
      display: flex;
      flex-direction: column;
      border: 1px solid;

      &:hover {
        cursor: pointer;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
      img {
        height: 170px;
        width: 280px;
        object-fit: contain;
        border: 1px solid;
      }

      p {
        padding: 12px 16px;
        background-color: #b8b4d2;
        color: #121239;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }
  .rejectionHeader {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    background: #f1f0f8;
    width: 100%;

    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      gap: 12px;
      padding: 12px;

      li {
        padding: 12px;
        background-color: #b8b4d2;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: #121239;
        cursor: pointer;
        text-transform: capitalize;
      }
    }
  }
}
