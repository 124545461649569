.container {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  border: 2px solid transparent;
  overflow: none;

  select {
    border: 1px solid orange;
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 4px;
    background-image: linear-gradient(25deg, orange, red);
    color: white;
  }

  .controls {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.119);
    padding: 12px;

    .thumbnailSection {
      display: flex;
      flex-direction: row;
      gap: 12px;
      margin: 0 auto;

      img {
        object-fit: contain;
        width: 120px;
        border-radius: 50%;
        background-color: white;
        border: 1px solid transparent;
        opacity: 0.8;

        &:hover {
          cursor: pointer;
          border: 1px solid rgb(143, 63, 63);
        }
      }
    }
  }
}

@media screen and (max-width: 512px) {
  .container {
    .controls {
      .thumbnailSection {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin: 0 auto;
        width: 412px;
        overflow-x: scroll;

        img {
          object-fit: contain;
          width: 68px;
          border-radius: 50%;
          background-color: white;
          border: 1px solid transparent;
          opacity: 0.8;

          &:hover {
            cursor: pointer;
            border: 1px solid rgb(143, 63, 63);
          }
        }
      }
    }
  }
}
