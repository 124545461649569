@import "../../../../styles/variables";
@import "../../../../styles/breakpoints";

.container {
  display: flex;
  width: 296px - 98px;
  background-color: $primary-color;
  flex-direction: column;
  padding: 24px;
  height: 100vh;
  position: sticky;
  top: 0;

  @include xs {
    width: 48px;
  }

  @include sm {
    width: 48px;
  }

  @include md {
    display: 120px;
  }
  @include lg {
    width: 186px;
  }

  @include xl {
    width: 246px;
  }

  .logo {
    display: block;
    padding: 16px;
    width: 148px;
    height: 40px;

    @include xs {
      display: none;
    }

    @include sm {
      display: none;
    }

    @include md {
      display: none;
    }
    @include lg {
      display: block;
    }

    @include xl {
      display: block;
    }
  }

  .mdlogo {
    display: block;
    width: 56px;
    height: 56px;
    margin-bottom: 12px;

    @include xs {
      display: block;
    }

    @include sm {
      display: block;
    }
    @include md {
      display: block;
    }
    @include lg {
      display: none;
    }

    @include xl {
      display: none;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 20px;
    background-color: $primary-color;
    border-radius: $default-border-radius;
    font-family: $heading-font-family;
    color: #7c6e90;
    font-weight: 500;
    width: 100%;

    p {
      @include xs {
        display: none;
      }
      @include sm {
        display: none;
      }
      @include md {
        display: none;
      }
      @include lg {
        display: block;
      }

      @include xl {
        display: block;
      }
    }

    &:hover {
      background-color: $quaternary-color;
      cursor: pointer;
      color: $secondary-color;
    }
  }

  a {
    text-decoration: none;
    color: $tertiary-color;
    display: flex;
    flex-direction: row;
    gap: 16px;

    &:hover {
      color: $secondary-color;
    }
  }
}
