@import "../../styles/variables";

body {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f1f0f8;

  .mainContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .logoSection {
      display: flex;
      align-items: center;

      img {
        width: 64px;
        height: 64px;
      }
      p {
        font-size: 36px;
        font-family: $heading-font-family;
        font-weight: 800;
        color: $secondary-color;
      }
    }

    .loginSection {
      width: 448px;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      background-color: $primary-color;
      padding: 24px;
      border-radius: 0.5rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .title {
        font-size: 22px;
        font-weight: 700;
        color: $secondary-color;
      }

      .inputSection {
        display: flex;
        flex-direction: column;
        gap: 8px;

        label {
          color: $secondary-color;
          font-size: 14px;
          font-weight: 600;
        }

        input {
          outline: none;
          padding: 8px 12px;
          border-radius: 0.2rem;
          border: none;
          border: 1px solid $senary-color;
          color: $secondary-color;
          font-weight: 700;
          font-family: Inter;
        }
      }

      .additionalSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      button {
        width: 100%;
        height: 40px;
        background: linear-gradient(25deg, orange, red);
        border: none;
        border-radius: 8px;
        color: white;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 700;

        &:hover {
          opacity: 0.9;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    background-color: #f1f0f8;
    img {
      width: 32px;
      height: 32px;
    }

    .mainContent {
      .logoSection {
        img {
          width: 48px;
          height: 48px;
        }
        p {
          font-size: 28px;
        }
      }
      .loginSection {
        width: 80vw;
      }
    }
  }
}
