.megaContainer {
  max-width: 1440px;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }
  .renderSection {
    padding: 12px;
    margin: 0 auto;
    display: flex;
    position: relative;

    p {
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 8px 12px;
      background-image: linear-gradient(25deg, orange, red);
      color: white;
      font-weight: 400;
      border-radius: 8px;
      font-size: 12px;
      &:hover {
        cursor: pointer;
        background-image: linear-gradient(25deg, red, orange);
      }
    }

    img,
    iframe {
      width: 330px;
      height: 420px;
      border: none;
      overflow: hidden;
    }
  }

  .thumbnailSection {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 0 12px;

    img {
      width: 64px;
      height: 64px;
      object-fit: contain;
      border-radius: 4px;
      border: 1px solid transparent;

      &:hover {
        cursor: pointer;
        border: 1px solid grey;
      }
    }
  }
}

.desktopContainer {
  display: none;

  .mainSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;

    .thumbnailSection {
      display: flex;
      flex-direction: column;
      gap: 8px;

      img {
        width: 128px;
        height: 128px;
        object-fit: contain;
        border: 1px solid transparent;

        &:hover {
          cursor: pointer;
          border: 1px solid grey;
        }
      }
    }

    .renderSection {
      display: flex;
      position: relative;

      p {
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 12px 16px;
        background-image: linear-gradient(25deg, orange, red);
        color: white;
        font-weight: 500;
        border-radius: 8px;
        &:hover {
          cursor: pointer;
          background-image: linear-gradient(25deg, red, orange);
        }
      }
      img,
      iframe {
        width: 610px;
        height: 763px;
        border: none;
        overflow: hidden;
      }
    }

    .cartSection {
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 100vh;
      gap: 0;

      img {
        width: 552px;
      }
    }
  }

  img {
    width: 100%;
  }
}

@media only screen and (min-width: 513px) {
  //Desktop Screen
  .container {
    display: none;
  }

  .desktopContainer {
    display: block;
  }
}

@media only screen and (max-width: 512px) {
  //Mobile Screen
  .container {
    display: flex;
  }

  .desktopContainer {
    display: none;
  }
}
