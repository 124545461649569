@tailwind base;
@tailwind components;
@tailwind utilities;

#test {
  background-color: green;
  padding: 24px;
}

.bg-green {
  background-color: green;
  padding: 24px;
}

Canvas {
  width: 100%;
  height: 100vh;
}

.picker {
  position: absolute !important;
  top: 74px;
  left: 70px;
  width: 90px !important;
  height: 90px !important;
}


.primary{
    background-image: linear-gradient(25deg,red,orange);

    &:hover{
        background-image: linear-gradient(25deg,orange,red);
    }
}

.secondary{
    background: transparent;
    border: 1px solid #B8B4D2;

    &:hover{
        background-color: #B8B4D2;
    }
}

.imageBackground{
    background: rgb(240,240,240);
    background: radial-gradient(circle, rgba(240,240,240,1) 0%, rgba(244,244,244,1) 40%, rgba(232,232,232,1) 86%);
}
