@import "../../../../styles/variables";
@import "../../../../styles/breakpoints";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1080px;

  @include md {
    display: flex;
    width: 810px;
  }

  @include lg {
    display: flex;
    width: 910px;
  }

  @include xl {
    display: flex;
    width: 1080px;
  }

  .notification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  p {
    font-size: 16px;
    color: $tertiary-color;
  }

  .primaryButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-image: $gradient;
    height: 40px;
    width: 176px;
    border-radius: $default-border-radius;

    &:hover {
      cursor: pointer;
      background-image: $gradient-hover;
    }
    img {
      width: 20px;
      opacity: 90%;
    }
    button {
      background-color: inherit;
      border: none;
      font-weight: 700;
      color: $primary-color;
    }
  }
}
