.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 12px;

  canvas {
    width: 100%;
    height: 100vh;
    object-fit: contain;
  }

  button {
    margin: 0 auto;
    padding: 12px 16px;
    border-radius: 4px;
    border: none;
    width: 50%;
    background-image: linear-gradient(25deg, orange, red);
    color: white;
    font-weight: 600;

    &:hover {
      cursor: pointer;
      background-image: linear-gradient(25deg, red, orange);
    }
  }
}
