.Desktopcontainer {
  display: flex;
  flex-direction: column;
  background-color: white;

  .contentSection {
    display: flex;
    flex-direction: row;
    max-width: 1280px;
    margin: 0 auto;
  }

  .detailsSection {
    width: 100%;
    height: 100%;
  }

  .arBox {
    width: 508px - 12px;
    height: 508px - 12px;
    object-fit: cover;
    padding: 12px;
  }

  .arLabelUpdated {
    display: flex;
    flex-direction: row;
    gap: 12px;
    background-image: linear-gradient(25deg, orange, red);
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 240px;
    border-radius: 8px;
    color: white;
    margin-left: 25%;
    margin-bottom: 2rem;
    font-size: 14px;
    font-weight: 500;
    color: white;

    &:hover {
      cursor: pointer;
      background-image: linear-gradient(25deg, red, orange);
    }

    img {
      width: 32px;
      height: 32px;
    }
  }

  .arLabel {
    color: white;
    background-image: linear-gradient(25deg, orange, red);
    border-radius: 8px;
    padding: 8px 12px;
    max-width: 160px;
    margin-left: 24rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      color: white;
      background-image: linear-gradient(25deg, red, orange);
      color: #fbf7f0;
    }
  }

  .thumbnailSection {
    display: flex;
    flex-direction: column;
    gap: 2px;

    img {
      width: 98px - 8px;
      height: 98px - 8px;
      padding: 4px;
      border: 1px solid transparent;
      object-fit: cover;

      &:hover {
        border: 1px solid black;
        cursor: pointer;
      }
    }
  }

  .renderSection {
    position: relative;
    margin-left: 1rem;

    video {
      width: 502px;
      height: 502px;
    }

    .glbSection {
      width: 502px;

      iframe {
        border: none;
        overflow: hidden;
      }
    }

    p {
      position: absolute;
      bottom: 8rem;
      right: 2rem;
      background-color: black;
      padding: 4px 8px;
      color: white;
      font-size: 12px;

      &:hover {
        cursor: pointer;
      }
    }

    .imageSection {
      position: relative;
      img {
        width: 508px;
        height: 508px;
        object-fit: cover;
      }

      p {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: black;
        font-size: 12px;
        color: white;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .glbSection {
      width: 508px;
      height: 508px;
    }
  }

  .detailsSection {
    width: 508px;
  }
}

.mobilecontainer {
  width: 100vw;
  display: none;
  background-color: white;

  img {
    width: 100%;
  }

  .arBox {
    width: 94%;
    height: 396px - 8px;
    object-fit: cover;
    padding: 12px;
  }

  .renderSection {
    position: relative;

    iframe {
      border: none;
    }

    .glbSection {
      iframe {
        border: none;
      }
    }

    video {
      margin: 1rem 2rem;
      width: 360px;
      height: 360px;
    }

    p {
      position: absolute;
      bottom: 1.4rem;
      right: 1.4rem;
      background-color: black;
      padding: 4px 8px;
      color: white;
      font-size: 12px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .arLabelUpdated {
    display: flex;
    flex-direction: row;
    gap: 12px;
    background-image: linear-gradient(25deg, orange, red);
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 240px;
    border-radius: 8px;
    color: white;
    margin-left: 15%;
    margin-bottom: 0.7rem;
    font-size: 14px;
    font-weight: 500;
    color: white;

    &:hover {
      cursor: pointer;
      background-image: linear-gradient(25deg, red, orange);
    }

    img {
      width: 32px;
      height: 32px;
    }
  }

  .arLabel {
    color: white;
    background-image: linear-gradient(25deg, orange, red);
    border-radius: 4px;
    padding: 4px 8px;
    max-width: 112px;
    margin-left: 8rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      color: black;
      background-image: linear-gradient(25deg, red, orange);
      color: white;
    }
  }

  .thumbnailSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    padding: 12px;
    width: 90%;
    overflow-x: scroll;
    margin-top: 1rem;

    img {
      width: 100px;
      height: 100px;
      border: 1px solid transparent;
      object-fit: contain;

      &:hover {
        border: 1px solid black;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mobilecontainer {
    display: block;
  }

  .Desktopcontainer {
    display: none;
  }
}
