@import "../../../styles/variables";

.no_margin {
  margin-top: -28px;
}

.container {
  background: $primary-color !important;

  td {
    padding: 16px;
  }

  table {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    border-collapse: collapse;
    position: relative;

    .primary {
      background-image: linear-gradient(25deg, orange, red);
      border: none;
      color: $primary-color;

      &:hover {
        border: none;
        background-image: linear-gradient(25deg, red, orange);
      }
    }

    thead {
      font-weight: 700;
      color: $secondary-color;
      background-color: darken($quaternary-color, 3%);
    }

    tr:nth-child(even) {
      background-color: $quaternary-color;
    }

    tbody {
      tr {
      }
    }

    button {
      background-color: transparent;
      border: none;
      color: $secondary-color;
      font-weight: 700;
      border: 1px solid $senary-color;
      width: 72px;
      padding: 8px;
      border-radius: 4px;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        border: 1px solid $secondary-color;
        background-color: $quaternary-color;
      }
    }
  }
}
