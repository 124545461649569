.container {
  display: flex;
  flex-direction: column;
  width: min(100vw, 1440px);
  height: 100vh;
  background-color: blue;
}

@media only screen and (max-width: 480px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: red;
    overflow: auto;
  }
}

.renderSection {
  background-color: orange;
  width: 90%;
  height: 100%;
  margin: 0 auto;
}
