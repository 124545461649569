@import "../../../styles/variables";

.titleSection {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .title {
    font-size: 29px;
    font-family: $heading-font-family;
    color: $secondary-color;
  }

  .subtitle {
    font-size: 18px;
    font-family: $body-font-family;
    color: $senary-color;
  }
}

.cardSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
