body {
  background-color: white;
}

.arContainer {
  width: 854px;
  background-color: rgb(230, 230, 230);
  display: block;

  canvas {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
}

.container {
  display: none;

  img {
    width: 100%;
  }

  .mainSection {
    .arContainer {
      width: 354px - 12px !important;
      padding: 12px;
      background-color: white;
    }
    .thumbnailSection {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      width: 100%;

      img {
        width: 94vw;
        padding: 12px;
      }
    }
  }

  p {
    padding: 8px 12px;
    background-color: rgb(213, 41, 117);
    color: white;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
    width: 50%;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }
  }
}

.desktopContainer {
  display: flex;
  flex-direction: column;

  p {
    padding: 8px 12px;
    background-color: rgb(213, 41, 117);
    color: white;
    display: flex;
    justify-content: center;
    width: 100px;
    margin-left: 24rem;
    border-radius: 16px;
    font-size: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  .mainSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding: 12px;
    gap: 48px;

    .thumbnailSection {
      width: 854px;
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 2 columns */
      grid-template-rows: repeat(3, auto); /* 3 rows */
      row-gap: 12px;
      column-gap: 12px;
      img {
        width: 422px;
        height: 634px;
      }
    }

    .cartSection {
      display: flex;
      flex-direction: column;

      img {
        width: 520px;
      }

      .buttonSection {
        display: flex;
        justify-content: center;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 502px;
          height: 45px;
          border: 2px solid rgb(213, 41, 117);
          background-color: transparent;
          color: rgb(213, 41, 117);
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 0.3px;
          transition: 0.2s;

          svg {
            width: 40px;
            height: 40px;
            stroke: blue;
          }

          .path1,
          .path2,
          .path3 {
            stroke: green;
          }

          &:hover {
            background-color: rgb(213, 41, 117);
            color: white;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    display: block;
  }

  .desktopContainer {
    display: none;
  }
}
