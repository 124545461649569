@import "../../styles/styles.module.scss";

body {
  width: 1400px;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  .glbSection {
    display: flex;
    width: 50%;
  }

  .exportSection {
    background-color: $senary-color;
    color: white;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px;

    .line {
      height: 4px;
      background-color: $secondary-color;
    }

    .outputSection {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 2rem;
      justify-content: center;
      align-items: center;

      .codeContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .codeSection {
        border-radius: 8px;
        background-color: $secondary-color;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 12px;
        font-size: 10px;
        justify-content: space-between;

        button {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 12px 16px;
          background-image: linear-gradient(25deg, orange, red);
          color: white;
          font-weight: 700;
          font-size: 12px;
          white-space: nowrap;
          border-radius: 8px;
          border: none;

          &:hover {
            background-image: linear-gradient(25deg, red, orange);
            cursor: pointer;
          }
        }
      }

      .qrContainer {
        display: flex;
        flex-direction: row;
        gap: 24px;
      }

      .qrSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          color: $secondary-color;
          font-weight: 700;
          padding: 12px;
        }
        .QRCode {
          border-radius: 8px;
          width: 212px - 56px;
          height: 212px - 56px;
          background-color: white;
          padding: 24px;

          &:hover {
            cursor: pointer;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
        img {
          border-radius: 8px;
          width: 248px;
          height: 248px;
          background-color: white;
          padding: 6px;

          &:hover {
            cursor: pointer;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
      }
    }

    .titleSection {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h2 {
        font-size: 20px;
      }
    }
  }
}
